// https://static.floodmapper.co.uk/static/cdn/
import React from 'react';
import { Link } from 'react-router-dom';

import getAssetUrl from '../lib/CDNAsset.js';

const CDNImage = ({ assetName, children, ...props }) => {
    // Construct the target URL for external links
    // const externalURL = `${process.env.process.env.REACT_APP_FMUK_CDN_BASE_URL}${assetName}`;
    const externalURL = getAssetUrl(assetName);

    return <img src={externalURL} {...props} />;
};

export default CDNImage;
