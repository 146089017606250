import React from 'react';
import Helmet from 'react-helmet';


function HeaderDescription({ description }) {

	return (
		<Helmet>
			{description && <meta name="description" content={description} />}
			{description && <meta name="og:description" content={description} />}
			{description && <meta name="twitter:description" content={description} />}
		</Helmet>
	);
}


export default HeaderDescription;