import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFM } from '../FMContext';

const splitDomain = (fullDomain) => {
    const allowedHostnames = ['floodmapper.co.uk', 'flood-mapper.com'];
    let subdomain = '';
    let hostname = '';

    // Loop through allowed hostnames to find the one that matches the end of fullDomain
    for (const allowed of allowedHostnames) {
        if (fullDomain.endsWith(allowed)) {
            hostname = allowed;
            // Remove the allowed hostname portion and the dot to get the subdomain
            subdomain = fullDomain.replace(`.${allowed}`, '');
            break;
        }
    }

    // If there is still a dot in subdomain, extract the first segment as the subdomain
    // and the remaining part as the hostname, allows for "lcl"
    if (subdomain.includes('.')) {
        const splitParts = subdomain.split('.');
        subdomain = splitParts[0];
        hostname = `${splitParts.slice(1).join('.')}.${hostname}`;
    } else {
        // No additional subdomain part, so set subdomain to www
        subdomain = 'www';
    }

    return { subdomain, hostname };
};


const CentralLink = ({ to, subdomain = null, dottedUnderline = false, children, ...props }) => {
    const { lang } = useParams();  // Capture the :lang parameter from the URL
    const { i18n } = useTranslation();
    const { currentSubdomain, currentHostname, currentProtocol, currentPort } = useFM();


    let langPrefix = "";
    if(lang && i18n.languages.indexOf(lang.toLowerCase()) > -1) {
        langPrefix = "/" + lang + "";
    }

    let isExternal = true;

    let calculatedSubdomain = subdomain ? subdomain : 'www';
    // Construct the target URL for external links
    const externalURL = `${currentProtocol}//${calculatedSubdomain}.${currentHostname}:${currentPort}${langPrefix}${to}`;
    // Determine if the current subdomain is "www", indicating internal navigation
    // based on the assumption that we are external on any link other than www, so we can browse new to floodnav, or browse new back to www
    if (currentSubdomain === "www" && calculatedSubdomain === 'www') {
        isExternal = false;
    }

    // On Cordova/Capacitor we do not cross any domains
    if(typeof window !== 'undefined' && window.cordova) {
        return <Link to={langPrefix+to} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></Link>;
    }

    // Normal web
    if (isExternal) {
        // Use a standard <a> tag for external links, preserving any passed props
        return <a href={externalURL} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></a>;
    } else {
        // Use React Router's <Link> for internal navigation within the SPA
        return <Link to={langPrefix+to} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></Link>;
    }
};

export default CentralLink;
