import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

// Universal Router component
const Router = ({ children, location, context }) => {
  if (typeof window === 'undefined') {
    // Server-side rendering
    return <StaticRouter location={location} context={context}>{children}</StaticRouter>;
  } else {
    // Client-side rendering
    if(window.cordova) {
      console.log(window.location);
      return <HashRouter basename="/">{children}</HashRouter>
    }
    return <BrowserRouter>{children}</BrowserRouter>;
  }
};

export default Router;