import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appUserHome: {
    metrics: [],
    faa: []
  },
  userId: null,
  idToken: null,
  fcmToken: null,
  appFocusCount: 0,
  homePageEditMode: false,
  llt: null,
  slt: null
};

const cdvState = createSlice({
  name: 'cdvState',
  initialState: initialState,
  reducers: {
    TOGGLE_HOMEPAGE_EDIT_MODE: function(state, action) {
      console.log("AppHandleAction: ", 'TOGGLE_HOMEPAGE_EDIT_MODE');
      const newState = {...state};
      newState.homePageEditMode = !state.homePageEditMode;
      return newState;
    },
    APP_FOCUS_RESUME: function(state, action) {
      console.log("AppHandleAction: ", 'APP_FOCUS_RESUME');
      console.log("Process app resume");
      const newState = {...state};
      ++newState.appFocusCount;
      return newState;
    },
    APP_SET_SLT: function(state, action) {
      console.log("AppHandleAction: ", 'APP_SET_SLT', JSON.stringify(action));
      const newState = {...state};
      newState.slt = action.payload.slt;
      return newState;
    },
    APP_SET_LLT: function(state, action) {
      console.log("AppHandleAction: ", 'APP_SET_LLT', JSON.stringify(action));
      const newState = {...state};
      newState.llt = action.payload.llt;
      return newState;
    },
    APP_SET_USER_ID: function(state, action){
      console.log("AppHandleAction: ", 'APP_SET_USER_ID', JSON.stringify(action));
      const newState = {...state};
      newState.userId = action.payload.userId;
      return newState;
    },
    APP_SET_IDTOKEN: function(state, action) {
      console.log("AppHandleAction: ", 'APP_SET_IDTOKEN', JSON.stringify(action));
      const newState = {...state};
      newState.idToken = action.payload.idToken;
      return newState;
    },
    APP_SET_FCMTOKEN: function(state, action) {
      console.log("AppHandleAction: ", 'APP_SET_FCMTOKEN', JSON.stringify(action));
      const newState = {...state};
      newState.fcmToken = action.payload.token;
      return newState;
    },
    GET_APP_VIEW_HOME_REQUEST: function(state, action) {
      console.log("AppHandleAction: ", 'GET_APP_VIEW_HOME_REQUEST');
      // not interested in this for now
      return state;
    },
    GET_APP_VIEW_HOME_SUCCESS: function(state, action) {
      console.log("AppHandleAction: ", 'GET_APP_VIEW_HOME_SUCCESS');
      const newState = {...state};
      newState.appUserHome = {...state.appUserHome};
      if(action && action.payload) {
        newState.appUserHome.metrics = action.payload.metrics || [];
        newState.appUserHome.faa = action.payload.faa || [];
      }
      return newState;
    },
    GET_APP_VIEW_HOME_FAILURE: function(state, action) {
      console.log("AppHandleAction: ", 'GET_APP_VIEW_HOME_FAILURE');
      return state;
    },
    USER_DID_SIGN_OUT: function(state, action) {
      console.log("AppHandleAction: ", 'USER_DID_SIGN_OUT');
      const newState = {...state};
      newState.userId = initialState.userId;
      newState.appUserHome = {...initialState.appUserHome};
      return newState;
    },
    USER_DELETE_REQUEST: function(state, action) {
      console.log("AppHandleAction: ", 'USER_DELETE_REQUEST');
      const newState = {...state};
      return newState;
    },
    USER_DELETE_SUCCESS: function(state, action) {
      console.log("AppHandleAction: ", 'USER_DELETE_SUCCESS');
      const newState = {...state};
      newState.userId = initialState.userId;
      newState.appUserHome = {...initialState.appUserHome};
      return newState;
    },
    USER_DELETE_FAILURE: function(state, action) {
      console.log("AppHandleAction: ", 'USER_DELETE_FAILURE');
      const newState = {...state};
      return newState;
    },
    USER_METRIC_REQUEST: function(state, action) {
      console.log("AppHandleAction: ", 'USER_METRIC_REQUEST');
      return state;
    },
    USER_METRIC_SUCCESS: function(state, action) {
      console.log("AppHandleAction: ", 'USER_METRIC_SUCCESS');
      return state;
    },
    USER_METRIC_FAILURE: function(state, action) {
      console.log("AppHandleAction: ", 'USER_METRIC_FAILURE');
      return state;
    },
    USER_METRIC_DELETE_REQUEST: function(state, action) {
      console.log("AppHandleAction: ", "USER_METRIC_DELETE_REQUEST");
      return state;
    },
    USER_METRIC_DELETE_SUCCESS: function(state, action) {
      console.log("AppHandleAction: ", "USER_METRIC_DELETE_SUCCESS");
      return state;
    },
    USER_METRIC_DELETE_FAILURE: function(state, action) {
      console.log("AppHandleAction: ", "USER_METRIC_DELETE_FAILURE");
      return state;
    },
  },
});

// Export the action creators
export const { APP_SET_FCMTOKEN, APP_SET_IDTOKEN, APP_SET_USER_ID, APP_FOCUS_RESUME, TOGGLE_HOMEPAGE_EDIT_MODE, APP_SET_SLT, APP_SET_LLT } = cdvState.actions;

export default cdvState.reducer;
