import React from 'react';
import Helmet from 'react-helmet';


function HeaderEventStartEndTime({ startTime, endTime }) {

	return (
		<Helmet>
		  <meta property="event:start_time" content={startTime} />
		  <meta property="event:end_time" content={endTime} />
		</Helmet>
	);
}


export default HeaderEventStartEndTime;