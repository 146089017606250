import React from 'react';
import Helmet from 'react-helmet';

function HeaderLocality({ townName, region, country }) {

  return (
    <Helmet>
      {townName && <meta property="og:locality" content={townName} />}
      {region && <meta property="og:region" content={region} />}
      {country && <meta property="og:country-name" content={country} />}
    </Helmet>
  );
}

export default HeaderLocality;
