import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const UncaughtBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to handle the force reload
    const forceReload = () => {
        if(typeof window === 'undefined')
            return;
        navigate(window.location.pathname, { replace: true }); // Navigate to the current path
        window.location.reload(); // Then force a browser reload
    };

    const forceHome = () => {
        if(typeof window === 'undefined')
            return;
    	navigate("/", { replace: true }); // Navigate to the current path
        window.location.reload(); // Then force a browser reload
    }

    // Error boundary logic in functional components (this is a simplified simulation)
    useEffect(() => {
        if(typeof window === 'undefined')
            return;
        const errorListener = (error) => {
            console.error(error);
            setError(error);
            setHasError(true);
        };

        window.addEventListener('error', errorListener);

        return () => {
            window.removeEventListener('error', errorListener);
        };
    }, []);

    if (hasError) {
        // Custom fallback UI
        return (
            <div>
                <h2>Error</h2>
                <h4>Something went wrong.</h4>
                <p>We were unable to display this page at this time</p>
                <p>You can try again to load this page by <Button variant="dark" size="sm" onClick={forceReload}>Clicking Here</Button></p>
                <p> -OR- </p>
                <p>You can go back to our home page <Button variant="secondary" size="sm" onClick={forceHome}>Here</Button></p>
            </div>
        );
    }

    // Normally, just render children
    return children;
};

export default UncaughtBoundary;
