// FMContext.js
import React, { createContext, useContext, useMemo } from 'react';

// Utility function to split domain into subdomain and hostname
const splitDomain = (fullDomain) => {
    const allowedHostnames = ['floodmapper.co.uk', 'flood-mapper.com'];
    let subdomain = '';
    let hostname = '';

    for (const allowed of allowedHostnames) {
        if (fullDomain.endsWith(allowed)) {
            hostname = allowed;
            subdomain = fullDomain.replace(`.${allowed}`, '');
            break;
        }
    }

    // allow for [subdomain].stg.tld
    if (subdomain.includes('.')) {
        const splitParts = subdomain.split('.');
        subdomain = splitParts[0];
        hostname = `${splitParts.slice(1).join('.')}.${hostname}`;
    }

    // Fallback
    if(subdomain === '') {
        subdomain = 'www';
    }

    return { subdomain, hostname };
};

// Context and hook for accessing domain data
const FMContext = createContext();

export const FMProvider = ({ ssrSubdomain, children }) => {
    const contextValue = useMemo(() => {
        let currentSubdomain = "www";
        let currentHostname = process.env.REACT_APP_EXTERNAL_TLD || "floodmapper.co.uk";
        let currentProtocol = process.env.REACT_APP_EXTERNAL_PROTO || "https:";
        let currentPort = process.env.REACT_APP_EXTERNAL_PORT || "443";

        if (typeof window !== 'undefined') {
            // Update values based on client environment
            const { subdomain, hostname } = splitDomain(window.location.hostname);
            currentSubdomain = subdomain;
            currentHostname = hostname;
            currentProtocol = window.location.protocol;
            currentPort = window.location.port || ""; // Default to empty if no specific port
        } else {
            console.log("FMProvider -> SSR Context", ssrSubdomain);
            const { subdomain, hostname } = splitDomain(ssrSubdomain);
            currentSubdomain = subdomain;
            currentHostname = hostname;
            // don't override protocol and port and leave these from the application config
        }

        // inconsistent usage guard
        if (!currentProtocol.endsWith(":")) {
            currentProtocol += ":";
        }

        return { currentSubdomain, currentHostname, currentProtocol, currentPort };
    }, []);

    return <FMContext.Provider value={contextValue}>{children}</FMContext.Provider>;
};

export const useFM = () => useContext(FMContext);
