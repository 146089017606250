import React from 'react';
import Helmet from 'react-helmet';


function HeaderOgType({ ogType }) {

	return (
		<Helmet>
		  <meta name="og:type" property="og:type" content={ogType} />
		</Helmet>
	);
}


export default HeaderOgType;