import React from 'react';
import Helmet from 'react-helmet';


function HeaderTitle({ title }) {

	return (
		<Helmet>
		  <title>{title}</title>
		  <meta name="og:title" property="og:title" content={title} />
		  <meta name="twitter:title" property="twitter:title" content={title} />
		</Helmet>
	);
}


export default HeaderTitle;