

const getAssetUrl = function(assetName) {
	// if(process && process.env && process.env.REACT_APP_FMUK_CDN_BASE_URL) {
	// 	return process.env.REACT_APP_FMUK_CDN_BASE_URL + "/" + assetName;
	// }
	return `https://static.floodmapper.co.uk/static/cdn/${assetName}`;

	return null;
}

export default getAssetUrl;