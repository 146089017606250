import { createSlice } from '@reduxjs/toolkit';

const appContext = createSlice({
  name: 'appContext',
  initialState: {
    appContext: null
  },
  reducers: {
    // Reducer to set the map view
    setAppContext: (state, action) => {
      state.appContext = action.payload.appContext;
    },
  },
});

// Export the action creators
export const { setAppContext } = appContext.actions;

export const CONTEXTS = {
	APP_CONTEXT_WEB: 'WEB',      /* Web application */
	APP_CONTEXT_PWA: 'PWA',      /* Progressive Web App */
	APP_CONTEXT_TWA: 'TWA',      /* Depr: TWA */
	APP_CONTEXT_XPA: 'XPA'       /* FMUK X-Platform App */
};

export default appContext.reducer;
