import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CANON_BASE_URL_COM = "flood-mapper.com";
const CANON_BASE_URL_UK = "floodmapper.co.uk";

function HeaderCanonicalUrl({ path, overrideDefaultWebsite = "COM", customSubdomain = "www" }) {
	const { i18n } = useTranslation();

	const languageList = i18n.languages;  // Available languages
	const selectedLanguage = i18n.language;  // Current selected language

	// Initialize the URL with https and the subdomain
	let canonUrl = "https://" + customSubdomain + ".";
	let fmukUrl = "https://" + customSubdomain + "." + CANON_BASE_URL_UK;
	let fmcomUrl = "https://" + customSubdomain + "." + CANON_BASE_URL_COM;

	// Choose the base URL depending on the hostname
	switch (overrideDefaultWebsite) {
	  case "UK":
	    canonUrl += CANON_BASE_URL_UK;
	    break;
	  default:
	    canonUrl += CANON_BASE_URL_COM;
	    break;
	}

	// Append the path to the URL
    canonUrl += encodeURI(path);
    fmukUrl += encodeURI(path);
    fmcomUrl += encodeURI(path);

	return (
	<Helmet>
	  <link rel="canonical" href={canonUrl} />
	  <link rel="alternate" href={fmukUrl} hreflang="en-GB" />
	  <link rel="alternate" href={fmcomUrl} hreflang="en" />
	  
	  <meta property="og:url" content={canonUrl} />
	</Helmet>
	);
}

export default HeaderCanonicalUrl;