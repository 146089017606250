import React from 'react';

import Helmet from 'react-helmet';

import HeaderTitle from './HeaderTitle.js';
import HeaderDescription from './HeaderDescription.js';
import HeaderCanonicalUrl from './HeaderCanonicalUrl.js';
import HederLatLng from './HeaderLatLng.js';
import HeaderLocality from './HeaderLocality.js';
import HeaderEventStartEndTime from './HeaderEventStartEndTime.js';
import HeaderOgType from './HeaderOgType.js';

import loadable from '@loadable/component';

const HeaderImage = loadable(() => import('./HeaderImage.js'));


function CommonHeaders({
	title,
	description,
	canonicalUrlPath,
	canonicalDefaultWebsite,
	canonicalSubdomain,
	ogType,
	lat,
	lng,
	townName,
	region,
	country,
	eventStartTime,
	eventEndTime,
	imageUrl
}) {

	return <>
		{title && <HeaderTitle title={title} />}
		{description && <HeaderDescription description={description} />}
		{canonicalUrlPath && <HeaderCanonicalUrl path={canonicalUrlPath} overrideDefaultWebsite={canonicalDefaultWebsite} customSubdomain={canonicalSubdomain} />}
		{ogType && <HeaderOgType ogType={ogType} />}
		{lat && lng && <HederLatLng lat={lat} lng={lng} ogType={ogType} />}
		{(townName || region || country) && <HeaderLocality townName={townName} region={region} country={country} ogType={ogType} />}
		{eventEndTime && eventStartTime && <HeaderEventStartEndTime startTime={eventStartTime} endTime={eventEndTime} />}
		{imageUrl && <HeaderImage imageUrl={imageUrl} />}
		<Helmet>
			{imageUrl && <meta name="twitter:card" content="summary_large_image" />}
			<meta name="twitter:site" content="@FloodMapperUK" />
			<meta name="fb:pages" content="252959417893280" />
			<meta name="fb:pages" content="169822776222035" />
		</Helmet>
	</>
}


export default CommonHeaders;