import React from 'react';
import Helmet from 'react-helmet';

function HeaderLatLng({ lat, lng, ogType }) {
  // Ensure lat and lng are floats
  const formattedLat = parseFloat(lat);
  const formattedLng = parseFloat(lng);

  // Check if lat and lng are valid numbers
  const isValidLat = !isNaN(formattedLat);
  const isValidLng = !isNaN(formattedLng);

  return (
    <Helmet>
      {(ogType === 'place' || ogType === 'event') && isValidLat && <meta property={ogType + ":location:latitude"} content={formattedLat.toFixed(6)} />}
      {(ogType === 'place' || ogType === 'event') && isValidLng && <meta property={ogType + ":location:longitude"} content={formattedLng.toFixed(6)} />}
      {isValidLat && isValidLng && <meta name="geo.position" content={`${formattedLat.toFixed(6)}; ${formattedLng.toFixed(6)}`} />}
    </Helmet>
  );
}

export default HeaderLatLng;
