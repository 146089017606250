// Utility function to send events to Google Analytics
const sendToGA = (eventName, eventCategory, customOpts = {}) => {
	if(typeof window !== 'undefined') {
		if (window && window.gtag) {
			let opts = {
				event_category: eventCategory,
				page_location: window.location.href,
				page_title: document.title,
				...customOpts
			};
			try {
				window.gtag('event', eventName, opts);
				console.log("GA: ", eventCategory + '/' + eventName);
			} catch (error) {
				// Gracefully handle errors so JS errors from analytics don't impact the application
				console.error("Google Analytics Error:", error);
			}
		}
	}
};

export const sendClickStationUpstreamButton = () => {
	sendToGA('click_station_go_upstream', 'Station Upstream Navigation');
};

export const sendClickStationDownstreamButton = () => {
	sendToGA('click_station_go_downstream', 'Station Downstream Navigation');
};

export const sendClickNearByStationButton = (clickType) => {
	sendToGA('click_station_go_nearby', 'Station Nearby Navigation', {
		clickType: clickType
	})
};

export const PlaceFinderSearch = (type) => {
	sendToGA('place_finder_search', 'Place Finder', {
		type: type ? type : "search-place-type-unknown"
	});
};

export const sendOpenSpillCountExplainModal = () => {
	sendToGA('spill_count_modal_open', 'Spill Count Modal - Open');
};

export const sendNavigateToAnotherSewageReport = (placeId, placeName) => {
	sendToGA('click_goto_other_sewage_report', 'Sewage Reports Navigation (To Place)', {
		placeId: placeId,
		placeName: placeName
	});
};

export const sendNavigateToSearchSewageReport = () => {
	sendToGA('click_goto_search_sewage_report', 'Sewage Reports Navigation (To Search)');
};

export const sendXPAResume = () => {
	sendToGA('app_resume', 'XPA Interaction');
};

export const sendXPAOpen = () => {
	sendToGA('app_open', 'XPA Interaction');
};

export const sendXPASignIn = () => {
	sendToGA('app_signin', 'XPA Interaction');
};

export const sendXPASignOut = () => {
	sendToGA('app_signout', 'XPA Interaction');
};

export const sendXPAFAAModalCloseNoSave = () => {
	sendToGA('app_famodal_push_nosave', 'XPA FAA Modal Interaction');
};

export const sendXPAFAAModalSavePushSettings = () => {
	sendToGA('app_famodal_push_save', 'XPA FAA Modal Interaction');
};

export const sendXPAFAAModalEnableSwitchToggle = () => {
	sendToGA('app_famodal_toggle_notifications', 'XPA FAA Modal Interaction');
};
export const sendXPAStationUnsubscribe = () => {
	sendToGA('app_station_unsubscribe', 'XPA Interaction');
};
export const sendXPAFAAUnsubscribe = () => {
	sendToGA('app_metric_unsubscribe', 'XPA Interaction');
}
export const sendXPAMetricModalClose = () => {
	sendToGA('app_metricmodal_close', 'XPA Metric Modal Interaction');
};
export const sendXPAMetricModalOpen = () => {
	sendToGA('app_metricmodal_open', 'XPA Metric Modal Interaction');
};
export const sendXPAMetricDelete = () => {
	sendToGA('app_metricmodal_delete', 'XPA Metric Modal Interaction');
	sendXPAStationUnsubscribe();
};
export const sendXPAStationSubscribe = () => {
	sendToGA('app_station_subscribe', 'XPA Interaction');
};
export const sendXPAFAASubscribe = () => {
	sendToGA('app_metric_subscribe', 'XPA Interaction');
}

export const sendXPAChangeHomePageEditMode = (enabled) => {
	sendToGA('app_famodal_toggle_hp_edit_mode', 'XPA Interaction', {
		enabled: enabled ? true : null,
		disable: !enabled ? true : null
	});
};

export const sendXPAFAAModalOpen = () => {
	sendToGA('app_famodal_open', 'XPA FAA Modal Interaction');
};

export const sendMapLegendClick = (mapName) => {
	sendToGA("click_maplegend", "FloodNav Interaction", {
		map_name: mapName
	});
};

export const sendMapResetClick = (mapName) => {
	sendToGA("click_mapreset", "FloodNav Interaction", {
		map_name: mapName
	});
};

export const sendViewByAreaClick = (mapName) => {
	sendToGA("click_viewbyarea", "FloodNav Interaction", {
		map_name: mapName
	});
};

export const sendViewByRouteClick = (mapName) => {
	sendToGA("click_viewbyroute", "FloodNav Interaction", {
		map_name: mapName
	});
};

export const sendViewAreaOnMapClick = (mapName, viewArea) => {
	sendToGA("click_viewareaonmap", "FloodNav Interaction", {
		map_name: mapName,
		view_area: viewArea
	});
};

export const sendViewAreaInfoClick = (mapName, viewArea) => {
	sendToGA("click_viewareainfo", "FloodNav Interaction", {
		map_name: mapName,
		view_area: viewArea
	});
};

