import { createSlice } from '@reduxjs/toolkit';

const csrHandler = createSlice({
  name: 'csrHandler',
  initialState: {
    isHydrated: false,
  },
  reducers: {
    // Reducer to set the map view
    setHydrationComplete: (state, action) => {
      state.isHydrated = true;
    }
  },
});

// Export the action creators
export const { setHydrationComplete } = csrHandler.actions;
export default csrHandler.reducer;
